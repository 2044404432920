import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { appConfiguration } from 'src/app/Classes/config';
import { WebServicesService } from 'src/app/Classes/web-services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {

  selectedOrder: any;
  constructor(private route: ActivatedRoute, private api: WebServicesService) { }

  ngOnInit() {
    this.selectedOrder = appConfiguration.selectedOrder;
    console.log(this.selectedOrder);
  }

  updateOrderStatus(order_status: any) {
    this.api.updateOrder(this.selectedOrder.order_id, order_status, (res: any) => {
      if (res['status']) {
        Swal.fire({
          title: "#" + this.selectedOrder.order_id + " Updated!",
          text: "Order status has been set to " + order_status,
          icon: "success"
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "There is an error while updating order #" + this.selectedOrder.order_id,
          icon: "error"
        });
      }
    });
  }

}
