import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getRequest, postRequest } from './web-api.service';

@Injectable({
  providedIn: 'root'
})
export class WebServicesService {

  http: HttpClient;
  host: any;
  constructor(private httpHandler: HttpClient) {
    this.http = httpHandler;
    this.host = "https://api.quality-digitizing.com/";
  }
  getAllOrders(callback: Function) {
    let apiURL = this.host + "order_list.php";
    postRequest({}, apiURL, this.http, (response: any) => {

      callback(response);
    });
  }

  getMessagesList(callback: Function) {
    let apiURL = this.host + "get_messages_list.php";
    postRequest({}, apiURL, this.http, (response: any) => {
      callback(response);
    }, (err: any) => {
      callback(err);
    });
  }

  getOrderChat(order_id: any, callback: Function) {
    let apiURL = this.host + "get_order_messages.php";
    let body = {
      "message_order_id": order_id
    }
    postRequest(body, apiURL, this.http, (response: any) => {
      callback(response);
    });
  }

  loginAdmin(email: any, pass: any, callback: Function) {
    let apiURL = this.host + "user_login.php";
    let body = {
      "email": email,
      "password": pass
    }
    postRequest(body, apiURL, this.http, (response: any) => {
      callback(response);
    }, (err: any) => {
      callback(err);
    });
  }

  updateOrder(order_id: any, order_status: any, callback: Function) {
    let apiURL = this.host + "update_order.php";
    let body = {
      "order_id": order_id,
      "order_status": order_status
    }
    postRequest(body, apiURL, this.http, (response: any) => {
      callback(response);
    }, (err: any) => {
      callback(err);
    });
  }
  newOrderMessage(order_id: any, message: any, callback: Function) {
    let apiURL = this.host + "new_order_message.php";
    let body = {
      "message": message,
      "is_support": 1,
      "message_order_id": order_id
    }
    console.log(body);
    postRequest(body, apiURL, this.http, (response: any) => {
      callback(response);
    });
  }
}
