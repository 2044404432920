
import { HttpClient } from '@angular/common/http';


export function getRequest(apiURL: string, httpHandler: HttpClient, callback: Function, errorBack: Function = ()=>{}) {
    httpHandler.get(apiURL).subscribe((data: any) => {
       // console.log("Http Success");
        callback(data);
    }, err => {
        console.log("Http Error");
        errorBack(err);
    });
}

export function postRequest(body:any,apiURL: string, httpHandler: HttpClient, callback: Function, errorBack: Function = ()=>{}) {
    httpHandler.post(apiURL,body).subscribe((data: any) => {
     //   console.log("Http Success");
        callback(data);
    }, err => {
        console.log("Http Error");
        errorBack(err);
    });
}

