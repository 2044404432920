import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { DataTable } from 'simple-datatables';
import { WebServicesService } from 'src/app/Classes/web-services.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { appConfiguration } from 'src/app/Classes/config';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @ViewChild('tableRow') tableRow!: ElementRef;

  obj = {
    primary: "#6571ff",
    secondary: "#7987a1",
    success: "#05a34a",
    info: "#66d1d1",
    warning: "#fbbc06",
    danger: "#ff3366",
    light: "#e9ecef",
    dark: "#060c17",
    muted: "#7987a1",
    gridBorder: "rgba(77, 138, 240, .15)",
    bodyColor: "#000",
    cardBg: "#fff",
    fontFamily: "'Roboto', Helvetica, sans-serif"
  }


  /**
 * Doughnut chart
 */
  public doughnutChartOptions: ChartConfiguration['options'] = {
    aspectRatio: 2,
    plugins: {
      legend: {
        display: true,
        labels: {
          color: this.obj.bodyColor,
          font: {
            size: 13,
            family: this.obj.fontFamily
          }
        }
      },
    }
  };

  doughnutChartData: ChartData<'doughnut'> = {

    datasets: [
      {
        label: 'Population (millions)',
        backgroundColor: [],
        hoverBackgroundColor: [],
        borderColor: '',
        hoverBorderColor: [],
        data: [],
      },
    ],
  };
  public doughnutChartType: ChartType = 'doughnut';

  dataContainer: any;

  constructor(private api: WebServicesService, private _router: Router, private renderer: Renderer2) {

    this.api.getAllOrders((res: any) => {

      this.dataContainer = res;
      this.processChartData(this.dataContainer.chart);
      setTimeout(() => {
      //  const dataTable = new DataTable("#dataTableExample");
      }, 100);

      //  console.log(res);

    });

  }

  private processChartData(chartData: any[]): void {
    //this.doughnutChartLabels = chartData.map((data) => data.order_status);
    this.doughnutChartData.labels = chartData.map((data) => data.order_status || "Unknown");
    this.doughnutChartData.datasets[0].data = chartData.map((data) => Number(data.status_count));
    this.doughnutChartData.datasets[0].backgroundColor = [
      this.obj.primary,
      this.obj.danger,
      this.obj.info,
    ];
    this.doughnutChartData.datasets[0].hoverBackgroundColor = [
      this.obj.primary,
      this.obj.danger,
      this.obj.info,
    ];
    this.doughnutChartData.datasets[0].hoverBorderColor = [
      this.obj.primary,
      this.obj.danger,
      this.obj.info,
    ];
    this.doughnutChartData.datasets[0].borderColor = this.obj.cardBg;
  }

  ngOnInit(): void {
  }

  orderDetail(data:any) {
    appConfiguration.selectedOrder = data;
    this._router.navigate(["order-detail"]);
  }

}
