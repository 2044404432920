<!-- <nav class="page-breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink=".">General pages</a></li>
    <li class="breadcrumb-item active" aria-current="page">Invoice</li>
  </ol>
</nav> -->

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="container-fluid d-flex justify-content-between">
          <div class="col-lg-3 ps-0">
            <a routerLink="." class="nobleui-logo d-block mt-3">Quality<span> Digitizing</span></a>
            <!-- <p class="mt-1 mb-1"><b>Quality Digitizing</b></p> -->
            <p>108,<br> Great Russell St,<br>London, WC1B 3NA.</p>
            <h5 class="mt-5 mb-2 text-muted">Invoice to :</h5>
            <p>{{selectedOrder.customer_name}},<br> {{selectedOrder.contact}}<br> {{selectedOrder.email}}</p>
          </div>
          <div class="col-lg-3 pe-0">
            <h4 class="fw-bold text-uppercase text-end mt-4 mb-2">Order Detail</h4>
            <h6 class="text-end mb-5 pb-4">#{{selectedOrder.order_id}}</h6>
            <!-- <p class="text-end mb-1">Order Total</p>
            <h4 class="text-end fw-normal"> {{selectedOrder.order_total}}</h4> -->
            <h6 class="mb-0 mt-3 text-end fw-normal mb-2"><span class="text-muted">Order Date
                :</span>{{selectedOrder.order_date}}
            </h6>
            <!-- <h6 class="text-end fw-normal"><span class="text-muted">Due Date :</span> 12th Jul 2022</h6> -->
          </div>
        </div>
        <div class="container-fluid mt-5 d-flex justify-content-center w-100">
          <div class="table-responsive w-100">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Item</th>
                  <th>Special Notes</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-end">
                  <td class="text-start">1</td>
                  <td class="text-start">{{selectedOrder.order_item}}</td>
                  <td class="text-start">{{selectedOrder.order_notes}}</td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
        <div class="container-fluid mt-5 w-100">
          <div class="row">
            <div class="col-md-6 ms-auto">
              <div class="table-responsive">
                <table class="table">
                  <tbody>
                    <tr>
                      <td>Sub Total</td>
                      <td class="text-end">${{selectedOrder.order_total}}</td>
                    </tr>
                    <!-- <tr>
                      <td>TAX (12%)</td>
                      <td class="text-end">$ 1,788.00</td>
                    </tr>
                    <tr>
                      <td class="text-bold-800">Total</td>
                      <td class="text-bold-800 text-end"> $ 16,688.00</td>
                    </tr>
                    <tr>
                      <td>Payment Made</td>
                      <td class="text-danger text-end">(-) $ 4,688.00</td>
                    </tr> -->
                    <tr class="bg-light">
                      <td class="text-bold-800">Order Total</td>
                      <td class="text-bold-800 text-end">${{selectedOrder.order_total}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid w-100">
          <button (click)="updateOrderStatus('Completed')" class="btn btn-primary float-end mt-4 ms-2"><i
              data-feather="send" appFeatherIcon class="me-2 icon-md"></i>Mark as (Completed)</button>
          <button (click)="updateOrderStatus('Waiting for payment')" class="btn btn-outline-primary float-end mt-4 ms-2"><i
              data-feather="send" appFeatherIcon class="me-2 icon-md"></i>Mark as (Waiting for Payment)</button> 
        </div>
      </div>
    </div>
  </div>
</div>