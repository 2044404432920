<div class="row">
  <div class="col-xl-4 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h6 class="card-title" >Orders Summary</h6>

        <canvas *ngIf="dataContainer" baseChart [data]="doughnutChartData" [options]="doughnutChartOptions"
          [type]="doughnutChartType">
        </canvas>

      </div>
    </div>
  </div>
  <div class="col-xl-8 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h6 class="card-title"  >Recent Orders</h6>
        <div class="table-responsive" *ngIf="dataContainer" style="height: 400px; overflow-x: scroll;">
          <table id="dataTableExample" class="table">
            <thead>
              <tr >
                <th>ID#</th>
                <th>Customer</th>
                <th>Date</th>
                <th>Total</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let row of dataContainer.data">
                <tr  >
                  <td>{{row.order_id}}</td>
                  <td>{{row.customer_name}}</td>
                  <td>{{row.order_date}}</td>
                  <td>Rs. {{row.order_total}}/-</td>
                  <td  ><span class="badge bg-dark" >{{row.order_status}} </span></td>
                  <td> <button class="btn btn-secondary" (click)="orderDetail(row)" >View</button></td>
                </tr>
              </ng-container>

            </tbody>
            <tfoot>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </div>

      </div>
    </div>
  </div>
</div>